import * as React from "react";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

export const TopBar: React.FC = () => (
  <AppBar position="static">
    <Toolbar>
      <Typography variant="h6">Lora Coverage</Typography>
    </Toolbar>
  </AppBar>
);
