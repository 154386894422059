import * as React from "react";
// @ts-ignore
import MapGL, { Source, Layer } from "react-map-gl";
import { heatmapLayer } from "../config";
import { FeatureCollection } from "geojson";

interface MapViewProps {
  data: FeatureCollection;
}

interface IViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
}

export const MapView: React.FC<MapViewProps> = ({ data }) => {
  const [viewport, setViewport] = React.useState<IViewport>({
    latitude: 37.97946,
    longitude: -0.687114,
    zoom: 15,
    bearing: 0,
    pitch: 0
  });

  return (
    <MapGL
      {...viewport}
      width="100%"
      height="100%"
      mapStyle={"mapbox://styles/r-hernandez-tvt/ck4k1fqwo19j91cp0h0ckpvwk"}
      onViewportChange={setViewport}
      mapboxApiAccessToken={
        "pk.eyJ1Ijoici1oZXJuYW5kZXotdHZ0IiwiYSI6ImNrNGpwbjI2YzA5bDczamxkdDk4ejBnaWQifQ.eUKqyZ1fN1765YrYxN0pcg"
      }
    >
      <Source type="geojson" data={data}>
        <Layer {...heatmapLayer} />
      </Source>
    </MapGL>
  );
};
