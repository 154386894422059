import { ILoraHeatmap } from "../models";
import { FeatureCollection, Feature } from "geojson";

export const mapLoraHeatmapToGeoJSON = (
  data: ILoraHeatmap[]
): FeatureCollection => ({
  type: "FeatureCollection",
  features: data.map<Feature>((element, index) => ({
    type: "Feature",
    properties: {
      id: index,
      rssi: element.rssi + 200,
      snr: element.snr + 20
    },
    geometry: {
      type: "Point",
      coordinates: [element.longitude, element.latitude]
    }
  }))
});
