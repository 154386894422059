import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface FetchErrorDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const FetchErrorDialog: React.FC<FetchErrorDialogProps> = ({
  open,
  handleClose
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {"Error al obtener los datos de cobertura"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Hubo un problema al obtener los datos de cobertura LoRa, refresque la
        página para intentarlo de nuevo
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="secondary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);
