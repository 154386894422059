import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Main } from "./components";

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Main />
    </>
  );
};

export default App;
