import * as React from "react";
import { TopBar } from "./top-bar.component";
import { MapView } from "./map-view.component";
import { FeatureCollection } from "geojson";
import { mapLoraHeatmapToGeoJSON } from "../mappers/lora-heatmap.mapper";
import { ILoraHeatmap } from "../models";
import { FetchErrorDialog } from "./fetch-error-dialog.component";

export const Main: React.FC = () => {
  const [errorDialog, setErrorDialog] = React.useState<boolean>(false);
  const [data, setData] = React.useState<FeatureCollection>({
    features: [],
    type: "FeatureCollection"
  });

  const handleClose = () => setErrorDialog(false);

  React.useEffect(() => {
    fetch("/api/measures")
      .then(response => {
        if (response.status !== 200) {
          setErrorDialog(true);
          return;
        }
        return response.json();
      })
      .then(json => {
        setData(mapLoraHeatmapToGeoJSON(json as ILoraHeatmap[]));
      })
      .catch(error => {
        console.error(error);
        setErrorDialog(true);
      });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <TopBar />
      <MapView data={data} />
      <FetchErrorDialog open={errorDialog} handleClose={handleClose} />
    </div>
  );
};
